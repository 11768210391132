import type {Column} from '@hconnect/uikit/src/lib2'
import {MarkdownText, mergeSx} from '@hconnect/uikit/src/lib2'
import {SxProps, Theme, Typography} from '@mui/material'
import {TFunction} from 'i18next'
import moment from 'moment-timezone'
import React from 'react'

import {EquipmentData, EventType, StatusWithCancellation} from '../../types'
import {generateMarkdownOptions, getHighlightedTitleWithLink} from '../../utils/highlightHelpers'
import {DateTimeFormat} from '../dateTime/DateTimeFormat'
import {EventStatusLabel} from '../eventProperties/EventStatusLabel'
import {OverdueLabel} from '../eventProperties/OverdueLabel'

type SecondaryProps = {
  sx?: SxProps<Theme>
  children: React.ReactNode
}

const Secondary: React.FC<SecondaryProps> = ({children, sx}) => {
  return (
    <Typography variant="body1" sx={mergeSx({fontWeight: 500}, sx)}>
      {children}
    </Typography>
  )
}

type LabelProps = {
  sx?: SxProps<Theme>
  children: React.ReactNode
}

const Label: React.FC<LabelProps> = ({children, sx}) => {
  return <Typography sx={mergeSx({fontSize: 12, lineHeight: '16px'}, sx)}>{children}</Typography>
}

type EventProps = {
  equipment?: EquipmentData
  title: string
  status: StatusWithCancellation
  description: string
  eventType: EventType
  dueDate: string
}

export const generateTitleColumn = <T extends EventProps>(
  t: TFunction | ((key: string) => string)
): Column<T> => {
  const title = t('shiftEvent.label.title')

  return {
    key: 'title',
    label: <Label>{title}</Label>,
    flex: 4,
    sortable: true,
    customTemplate: (item: T) => (
      <Typography
        variant="h4"
        color="textPrimary"
        sx={{fontSize: 16, fontWeight: 500, wordBreak: 'break-word'}}
      >
        {item.title}{' '}
        <OverdueLabel
          status={item.status}
          dueDate={moment(item.dueDate)}
          plantNow={moment()}
          translationFunc={t}
        />
      </Typography>
    )
  }
}

export const generateStatusColumn = <T extends EventProps>(
  t: TFunction | ((key: string) => string),
  type?: 'small' | 'regular'
): Column<T> => ({
  headProps: type === 'small' ? {style: {paddingLeft: '4px'}} : {},
  key: 'status',
  width: type === 'small' ? '0px' : undefined,
  label: <Label>{t('shiftEvent.label.status')}</Label>,
  sortable: true,
  customTemplate: (item: T) => (
    <Secondary>
      <EventStatusLabel status={item.status} iconOnly={type === 'small'} t={t} />
    </Secondary>
  )
})

export const generateDueDateColumn = <T extends EventProps>(
  t: TFunction | ((key: string) => string),
  timezone: string
): Column<T> => ({
  key: 'dueDate',
  label: <Label>{t('shiftEvent.label.dueDate')}</Label>,
  flex: 2,
  sortable: true,
  customTemplate: (item: T) => (
    <Secondary sx={{whiteSpace: 'nowrap'}}>
      <DateTimeFormat date={item.dueDate} timezone={timezone} />
    </Secondary>
  )
})

export const generateDescriptionColumn = <T extends EventProps>(
  t: TFunction | ((key: string) => string),
  freeSearchText?: string
): Column<T> => ({
  key: 'description',
  label: <Label>{t('shiftEvent.label.description')}</Label>,
  sortable: false,
  customTemplate: (item: T) => (
    <Typography
      component={'div'}
      sx={{
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden'
      }}
    >
      {item.description && (
        <MarkdownText options={generateMarkdownOptions(freeSearchText)}>
          {freeSearchText
            ? getHighlightedTitleWithLink(item.description, freeSearchText, true)
            : item.description}
        </MarkdownText>
      )}
    </Typography>
  )
})
