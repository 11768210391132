import {Typography} from '@mui/material'
import {TFunction} from 'i18next'
import {merge, orderBy} from 'lodash'
import moment, {Moment} from 'moment-timezone'
import React from 'react'

import {getRunningTimeData} from '../components/runningTimes'
import {RunningTimeDropDownItem} from '../components/shiftEventFormFields/StoppageTimeDropDown'
import {
  EquipmentCategory,
  EquipmentData,
  EquipmentRunningTimes,
  EquipmentType,
  Iso8601,
  RunningTime,
  RunningTimeStoppage,
  RunningTimeType,
  Stoppage,
  StoppageDto,
  StoppageCode,
  StoppageKind,
  StoppageType,
  StoppageTypeWithCode
} from '../types'

export const isStoppageTypeWithReason = (stoppageType: StoppageType): boolean =>
  stoppageType === 'other' ||
  stoppageType === 'coalMill' ||
  stoppageType === 'crusher' ||
  stoppageType === 'alternativeFuelInstallation' ||
  stoppageType === 'bypass'

export const isStoppageTypeWithCode = (stoppageType: StoppageType): boolean =>
  stoppageType === 'kiln' || stoppageType === 'cementMill' || stoppageType === 'rawMill'

export function stoppageDefaultObject<T>(initialData: T): Partial<StoppageDto> {
  const defaultRequiredData = {
    eventType: StoppageKind.Incident,
    status: 'none',
    title: '',
    description: '',
    stoppageType: 'kiln'
  }
  const stoppage = {...defaultRequiredData, ...initialData} as Partial<StoppageDto>

  if (isStoppageTypeWithCode(stoppage.stoppageType as StoppageType)) {
    merge(stoppage, {stoppageCode: undefined})
  } else {
    merge(stoppage, {stoppageReason: undefined})
  }

  return stoppage
}

export const getStoppageDescription = (
  item: Stoppage,
  stoppageCodes: {[key in StoppageTypeWithCode]: StoppageCode[]}
) => {
  if ('stoppageType' in item && 'stoppageCode' in item) {
    const {stoppageType, stoppageCode} = item
    const stoppage = stoppageCodes[stoppageType].find((item) => item.code === stoppageCode)
    return stoppage?.description
  }
  return null
}

export const getRunningTimeStoppageLabel = (
  stoppageCodes: {[key in StoppageTypeWithCode]: StoppageCode[]},
  runningTimeStoppage?: RunningTimeStoppage,
  stoppageType?: StoppageType
) => {
  if (stoppageType && runningTimeStoppage?.stoppageCode) {
    const stoppage = stoppageCodes[stoppageType]?.find(
      (item) => item.code === runningTimeStoppage.stoppageCode
    )
    const stoppageDescription = stoppage?.description
    return (
      <Typography variant="h5">
        {runningTimeStoppage.stoppageCode}
        {stoppageDescription && ` - ${stoppageDescription}`}
      </Typography>
    )
  }
}

export const getRunningTimeDropdownItems = (
  runningTimes: EquipmentRunningTimes[],
  language: string,
  t: TFunction
): RunningTimeDropDownItem[] =>
  orderBy(
    runningTimes
      .map(({runningTimes, equipment}) => {
        return runningTimes
          .filter(
            ({runningTimeType, stoppages}) =>
              runningTimeType === RunningTimeType.STOPPED && !stoppages
          )
          .map((runningTime) => {
            return {
              label: getRunningTimeData(runningTime, language, t),
              runningTime,
              equipment: equipment
            }
          })
      })
      .flat()
      .map((runningTime, index) => ({...runningTime, id: String(index)})),
    ({runningTime}) => new Date(runningTime.beginTechnical?.valueOf() || ''),
    'desc'
  )

export const STOPPAGE_TYPE_MAP: Record<EquipmentCategory, StoppageType> = {
  [EquipmentCategory.CEMENT]: 'cementMill',
  [EquipmentCategory.RAW]: 'rawMill',
  [EquipmentCategory.COAL]: 'coalMill',
  [EquipmentCategory.CLINKER]: 'kiln',
  [EquipmentCategory.CRUSHER]: 'crusher',
  [EquipmentCategory.UNKNOWN]: 'other'
}

/**
 * The expectations should map to
 * https://confluence.heidelbergcement.com/display/HCP/Running+times+stoppage+types
 * @param equipment
 * @returns
 */
export const getEventStoppageType = (equipment: EquipmentData): StoppageType => {
  if (equipment.equipmentType === EquipmentType.KILN) {
    return 'kiln'
  } else if (equipment.equipmentCategory) {
    return STOPPAGE_TYPE_MAP[equipment.equipmentCategory]
  } else {
    return 'other'
  }
}

/**
 * The stoppage is split and Part2 is created as a new stoppage.
 * The stoppageEnd of the original stoppage(now Part1) is updated to the splitTime by BE.
 * The stoppageStart of the new stoppage is updated to the split time.
 * @param originalStoppage
 * @param updatedStart Stoppage split time
 * @returns Partial<StoppageDto>
 */
export const getStoppageSplitInitialData = (
  originalStoppage: StoppageDto,
  updatedStart: Iso8601
): Partial<StoppageDto> => {
  return {
    eventType: StoppageKind.Incident,
    stoppageType: originalStoppage.stoppageType,
    stoppageStart: updatedStart,
    stoppageEnd: originalStoppage.stoppageEnd,
    originalSplitStoppageId: originalStoppage['id'],
    ...(originalStoppage.mainEquipment && {
      mainEquipment: originalStoppage.mainEquipment
    }),
    ...(originalStoppage.equipment && {
      equipment: originalStoppage.equipment
    }),
    ...(originalStoppage.processStage && {
      processStage: originalStoppage.processStage
    })
  }
}

export const getStoppageEnd = (stoppageEnd?: Moment, runningTimeEnd?: Moment) => {
  if (stoppageEnd && !runningTimeEnd) {
    return stoppageEnd
  }
  if (stoppageEnd && runningTimeEnd && stoppageEnd.isBefore(runningTimeEnd)) {
    return stoppageEnd
  }
  return runningTimeEnd
}

export const getStoppageDate = (stoppage: RunningTimeStoppage, rt: RunningTime) => {
  const stoppageStart = moment.utc(stoppage.start)
  const runningTimeStart = moment.utc(rt.begin)

  const from = stoppageStart.isBefore(runningTimeStart) ? rt.begin : stoppage.start
  const to = getStoppageEnd(stoppage.end, rt.end)

  return {from, to}
}
