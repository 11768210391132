import {sortBy} from 'lodash'
import moment, {Moment} from 'moment-timezone'

import {
  DowntimeStoppageCode,
  ParticularShift,
  Shift,
  DateRange,
  Config,
  FeatureFlag,
  WorkCenter,
  Iso4217,
  WorkOrderTarget
} from '../types'
import {getShift} from '../utils'

type ShiftCache = {
  expiresAfter: number // utc miliseconds
  particularShift: ParticularShift
  dateRange: DateRange
}

export class ConfigContext<T extends string> {
  public readonly timezone: string
  public readonly plantId: string
  public readonly country: string
  public readonly pxTrendAvailable: boolean
  public readonly cceAvailable: boolean
  public readonly defaultCurrency: Iso4217
  public readonly workOrderTarget: WorkOrderTarget
  public readonly featureFlags: FeatureFlag<T>
  public readonly kilnStoppageCodes: DowntimeStoppageCode[]
  public readonly finishMillStoppageCodes: DowntimeStoppageCode[]
  public readonly rawMillStoppageCodes: DowntimeStoppageCode[]
  public readonly workCenters: WorkCenter[]
  public readonly shifts: Shift[]
  public readonly defaultTimeRange = 'currentShift'

  private currentShiftCache: ShiftCache | undefined

  constructor(config: Config<T>) {
    this.timezone = config.timezone
    this.plantId = config.plantId
    this.country = config.country
    this.pxTrendAvailable = config.pxTrendAvailable
    this.cceAvailable = config.cceAvailable
    this.defaultCurrency = config.defaultCurrency
    this.workOrderTarget = config.workOrderTarget
    this.featureFlags = config.featureFlags
    this.shifts = config.shifts
    this.kilnStoppageCodes = sortBy(config.kilnStoppageCodes, 'description')
    this.finishMillStoppageCodes = sortBy(config.finishMillStoppageCodes, 'description')
    this.rawMillStoppageCodes = sortBy(config.rawMillStoppageCodes, 'description')
    this.workCenters = config.workCenters
  }

  /**
   * Returns current time on the plant in the timezone of that plant. This is usefull for
   * start-of-day calculations (i.e. a day starts at midnight but the timezone of the plant needs
   * to be considered)
   */
  public plantNow: () => Moment = () => {
    return moment().tz(this.timezone)
  }

  /**
   * returns the time range of the currently active shift in utc time
   */
  public getCurrentShift = (): ParticularShift => {
    const nowMoment = this.plantNow()
    const nowUnixTimeStamp = nowMoment.valueOf()

    // check and refresh cache if needed
    if (
      !this.currentShiftCache?.particularShift ||
      this.currentShiftCache.expiresAfter < nowUnixTimeStamp
    ) {
      const particularShift = getShift(nowMoment, this.shifts)

      const endDate = particularShift.endDate
      const startDate = particularShift.startDate

      this.currentShiftCache = {
        particularShift,
        expiresAfter: endDate.valueOf(),
        dateRange: {
          startDate,
          endDate
        }
      }
    }

    return this.currentShiftCache.particularShift
  }
}
