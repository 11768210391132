import {Autocomplete, Paper, TextField} from '@mui/material'
import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {INPUT_FORM_FIELD_SX} from '../../consts'
import {
  StoppageType,
  StoppageTypeWithCode,
  StoppageKind,
  ShifthandoverStoppageCode
} from '../../types'
import {getTranslationKey} from '../../utils/translation.utils'

type EventStoppageCodesDropDownProps = {
  errorText?: React.ReactNode
  onChange: (stoppageCode: ShifthandoverStoppageCode | undefined) => void
  value: string | undefined
  required?: boolean
  disabled?: boolean
  stoppageType: StoppageType
  finishMillStoppageCodes: ShifthandoverStoppageCode[]
  kilnStoppageCodes: ShifthandoverStoppageCode[]
  rawMillStoppageCodes: ShifthandoverStoppageCode[]
  stoppageKind?: StoppageKind
  translationPrefix?: string
}

function areCodesSame(option1: string, option2: string) {
  return option1 && option2 ? option1 === option2 : false
}

function getDisplayPath(option: string, availableStoppageCodes: ShifthandoverStoppageCode[]) {
  const stoppageCode = availableStoppageCodes.find((asc) => asc.code === option)
  return `${stoppageCode?.code} ${stoppageCode?.description || ''}`
}

const paper = (props) => <Paper elevation={8} style={{minWidth: '350px'}} {...props} />

export const EventStoppageCodesDropDown: React.FC<EventStoppageCodesDropDownProps> = ({
  stoppageType,
  stoppageKind,
  kilnStoppageCodes,
  finishMillStoppageCodes,
  rawMillStoppageCodes,
  errorText,
  onChange,
  value: selectedStoppageCode,
  required,
  disabled,
  translationPrefix
}) => {
  const {t} = useTranslation()
  const [inputValue, setInputValue] = React.useState<string>('')
  const [open, setOpen] = React.useState<boolean>(false)
  const availableStoppageCodes = useMemo<{
    [key in StoppageTypeWithCode]: ShifthandoverStoppageCode[]
  }>(
    () => ({
      kiln: kilnStoppageCodes,
      cementMill: finishMillStoppageCodes,
      rawMill: rawMillStoppageCodes
    }),
    [kilnStoppageCodes, finishMillStoppageCodes, rawMillStoppageCodes]
  )

  const codes: string[] = availableStoppageCodes[stoppageType]
    .filter(({type}) => !stoppageKind || type === stoppageKind)
    .map(({code}) => code)

  const value = selectedStoppageCode

  useEffect(() => {
    if (value) {
      const availableStoppages: ShifthandoverStoppageCode[] = availableStoppageCodes[stoppageType]
      setInputValue(getDisplayPath(value, availableStoppages))
    }
  }, [availableStoppageCodes, stoppageType, value])

  return (
    <Autocomplete
      data-test-id="stoppage-code-search"
      value={selectedStoppageCode ?? null}
      inputValue={inputValue}
      open={open && codes.length > 0}
      onOpen={() => {
        setOpen(true)
      }}
      onChange={(event, next, reason) => {
        if (reason === 'clear') {
          setInputValue('')
          onChange(undefined)
          return
        }
        if (next) {
          const nextStoppageCode: ShifthandoverStoppageCode = availableStoppageCodes[stoppageType]
            .filter(({type}) => !stoppageKind || type === stoppageKind)
            .find(({code}) => code === next)
          onChange(nextStoppageCode)
        }
      }}
      onClose={() => {
        setOpen(false)
      }}
      onInputChange={(event, newValue, reason) => {
        if (reason === 'input' && newValue !== inputValue) {
          setInputValue(newValue)

          if (!newValue && value) {
            onChange(undefined)
          }
        }
      }}
      options={codes}
      getOptionLabel={(plain) => {
        const stoppageCode = availableStoppageCodes[stoppageType].find((asc) => asc.code === plain)
        return `${plain} ${stoppageCode?.description || ''}`
      }}
      isOptionEqualToValue={areCodesSame}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={!!errorText}
            data-test-id="stoppage-code-search-input"
            helperText={errorText}
            label={t(getTranslationKey('shiftEvent.label.stoppageCode', translationPrefix))}
            required={required}
            placeholder={t(getTranslationKey('pleaseStartTyping', translationPrefix))}
            variant="filled"
            InputProps={{...params.InputProps, ...INPUT_FORM_FIELD_SX}}
          />
        )
      }}
      PaperComponent={paper}
      forcePopupIcon={false}
      renderOption={(props, option) => {
        const availableStoppages: ShifthandoverStoppageCode[] = availableStoppageCodes[stoppageType]
        return (
          <li {...props} data-test-id={`stoppage-code-search-suggestion-${option}`}>
            {getDisplayPath(option, availableStoppages)}
          </li>
        )
      }}
      disabled={disabled}
    />
  )
}
