import {isKilnRunning} from '@hconnect/hproduce/src/performanceDashboard/helpers'
import {ExpandMoreOutlined as ExpandIcon, FiberManualRecord as Circle} from '@mui/icons-material'
import {Box, Divider, Grid, Theme, Typography} from '@mui/material'
import {TFunction} from 'i18next'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ExpandedGroupIds} from '../../hooks/useExpandGroups'
import {
  EquipmentRunningTimes,
  KpiPerformance,
  RunningTime,
  RunningTimesEquipmentData,
  RunningTimeType
} from '../../types'
import {getHoursMinutes} from '../../utils'
import {getTranslationKey} from '../../utils/translation.utils'
import {EquipmentLabel} from '../shiftEventLabels'

import {EquipmentKpiTable} from './EquipmentKpiTable'

const statusStyle = {display: 'flex', alignItems: 'center'}

const getStatusIndicator = (
  t: TFunction,
  activeStatus?: RunningTimeType,
  translationPrefix?: string
) => (
  <>
    {activeStatus && isKilnRunning(activeStatus) && (
      <Typography
        mr={2}
        variant="caption"
        sx={(theme: Theme) => ({
          ...statusStyle,
          color: theme.palette.success.light
        })}
      >
        <Circle
          sx={(theme: Theme) => ({
            color: theme.palette.success.light,
            verticalAlign: 'text-bottom'
          })}
        />
        {t(getTranslationKey('runningTime.label.running', translationPrefix))}
      </Typography>
    )}
    {activeStatus && !isKilnRunning(activeStatus) && (
      <Typography
        mr={2}
        variant="caption"
        sx={(theme: Theme) => ({
          ...statusStyle,
          color: theme.palette.error.light
        })}
      >
        <Circle
          sx={(theme: Theme) => ({
            color: theme.palette.error.light,
            verticalAlign: 'text-bottom'
          })}
        />
        {t(getTranslationKey('runningTime.label.off', translationPrefix))}
      </Typography>
    )}
  </>
)

type Props = {
  isExpanded?: boolean
  equipment: RunningTimesEquipmentData
  kpiPerformance?: KpiPerformance
  runningTimePerformance?: EquipmentRunningTimes
  translationPrefix?: string
  isLast: boolean
  getStackedBarChartComponent?: (
    equipment: RunningTimesEquipmentData,
    runningTimes: RunningTime[]
  ) => React.ReactNode
  toggleExpandedEquipments?: (
    id: ExpandedGroupIds,
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => void
  expandButtonGap?: boolean
  activeStatus?: RunningTimeType
  showKpiStatusIndicator?: boolean
  onClick?: () => void
}

export const EquipmentPerformanceResult: React.FC<Props> = ({
  equipment,
  kpiPerformance,
  isExpanded,
  isLast,
  getStackedBarChartComponent,
  runningTimePerformance,
  translationPrefix,
  toggleExpandedEquipments,
  expandButtonGap,
  activeStatus,
  showKpiStatusIndicator = false,
  onClick
}) => {
  const {t} = useTranslation()

  return (
    <Grid
      data-test-id={`equipment-perf-${equipment.id}`}
      sx={[!!onClick && {cursor: 'pointer'}]}
      onClick={onClick}
    >
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        py={1.5}
        pl={
          expandButtonGap && !(kpiPerformance?.kpiAssignments.length && toggleExpandedEquipments)
            ? 3
            : 0
        }
      >
        <Box>
          {kpiPerformance?.kpiAssignments.length && toggleExpandedEquipments && (
            <ExpandIcon
              sx={[
                {
                  transition: 'transform 0.5s ease',
                  transform: 'rotate(-90deg)',
                  marginRight: 1,
                  fontSize: '16px',
                  cursor: 'pointer'
                },
                !!isExpanded && {
                  transform: 'rotate(0deg)'
                }
              ]}
              onClick={(event) => toggleExpandedEquipments(equipment.id, event)}
              data-state={isExpanded ? 'open' : 'closed'}
            />
          )}
        </Box>
        <Box width="100%">
          <Box mb={1} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1" display="flex" gap={2}>
              <EquipmentLabel withId={false} withText equipment={equipment} />
              {getStatusIndicator(t, activeStatus, translationPrefix)}
            </Typography>
            {runningTimePerformance?.totalStopped && (
              <Typography fontSize={12} color="text.secondary" display="flex">
                {/* show only hours and minutes */}
                {`${t(
                  getTranslationKey('summary.label.stoppedFor', translationPrefix)
                )} ${getHoursMinutes(runningTimePerformance.totalStopped)} h`}
              </Typography>
            )}
          </Box>
          <Box display={'flex'} alignItems={'stretch'} flexWrap={'wrap'} width="100%">
            <Box
              sx={{
                flex: '1',
                display: 'flex',
                alignItems: 'stretch',
                justifyContent: 'flex-start',
                borderRadius: 1,
                overflow: 'hidden'
              }}
            >
              {!runningTimePerformance
                ? null
                : getStackedBarChartComponent?.(
                    equipment,
                    runningTimePerformance?.runningTimes?.filter((rt) => rt.begin !== rt.end) || []
                  )}
            </Box>
          </Box>
        </Box>
      </Box>
      {isExpanded && kpiPerformance && (
        <EquipmentKpiTable
          kpiPerformance={kpiPerformance}
          translationPrefix={translationPrefix}
          showKpiStatusIndicator={showKpiStatusIndicator}
        />
      )}

      {!isLast && <Divider color="light" />}
    </Grid>
  )
}
