import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import {Box, Grid, SxProps, TextField, Theme, ToggleButton, ToggleButtonGroup} from '@mui/material'
import {isNil} from 'lodash'
import React, {FC, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {INPUT_FORM_FIELD_SX, REGULAR_SIZE} from '../../consts'
import {
  Priority,
  Stoppage,
  DowntimeStoppageCode,
  StoppageKind,
  shifthandoverStoppagesList,
  StoppageType,
  EquipmentRunningTimes
} from '../../types'
import {
  getEventStoppageType,
  getRunningTimeDropdownItems,
  isStoppageTypeWithCode,
  isStoppageTypeWithReason
} from '../../utils'
import {getTranslationKey} from '../../utils/translation.utils'
import {
  EventHighlight,
  EventTypeTag,
  SimpleDateTimePicker,
  EventDescriptionBox,
  EventStoppageTypeDropDown,
  CategoryDropDown,
  ProcessStageDropDown,
  PriorityDropDown
} from '../shiftEventFormFields'
import {EventStoppageCodesDropDown} from '../shiftEventFormFields/EventStoppageCode'
import {
  RunningTimeDropDownItem,
  RunningTimesData,
  StoppageTimeDropDown
} from '../shiftEventFormFields/StoppageTimeDropDown'

import {EquipmentSearchContainer} from './EquipmentSearchContainer'
import {SalesImpact} from './SalesImpact'

type PossibleErrors = keyof Stoppage | 'stoppageCode' | 'stoppageReason' | 'equipmentNumber'

type StoppageFormFieldsProps = {
  item: Partial<Stoppage>
  updateMultiPart: (delta: Partial<Stoppage>) => void
  validationError: Map<PossibleErrors, string>
  timezone: string
  plantId: string
  withSalesImpact?: boolean
  withPriority?: boolean
  withCategory?: boolean
  runningTimesData?: RunningTimesData<EquipmentRunningTimes>
  hideStoppageType?: boolean
  stoppageCodes: {
    kilnStoppageCodes: DowntimeStoppageCode[]
    rawMillStoppageCodes: DowntimeStoppageCode[]
    finishMillStoppageCodes: DowntimeStoppageCode[]
  }
  translationPrefix?: string
  durationEditOptions: {
    disableStart: boolean
    disableEnd: boolean
  }
  children: React.ReactNode
}

const stoppageToggleButtonGroupSx: SxProps<Theme> = (theme) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  backgroundColor: theme.palette.grey[100]
})

enum DateSelectionType {
  List = 'list',
  Manual = 'manual'
}

// eslint-disable-next-line complexity
export const StoppageFormFields: FC<StoppageFormFieldsProps> = ({
  item,
  updateMultiPart,
  validationError,
  children,
  timezone,
  plantId,
  withSalesImpact,
  withPriority,
  withCategory,
  runningTimesData,
  hideStoppageType,
  stoppageCodes,
  translationPrefix,
  durationEditOptions
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const [dateSelectionType, setDateSelectionType] = useState<DateSelectionType>(
    DateSelectionType.Manual
  )
  const [selectedRunningTimeId, setSelectedRunningTimeId] = useState<string>()

  const runningTimesList: RunningTimeDropDownItem[] = useMemo(
    () => getRunningTimeDropdownItems(runningTimesData?.data || [], language, t),
    [language, runningTimesData?.data, t]
  )

  const getErrorText = (key: PossibleErrors) => {
    const errorCode = validationError.get(key)
    return errorCode && t(getTranslationKey(errorCode, translationPrefix))
  }

  const onStoppageTypeChange = (type: StoppageType) => {
    if (type === item.stoppageType) return

    // remove the left over from the previous type
    if (isStoppageTypeWithCode(type)) {
      // quick hack to remove the previous value but the same time satisfy typescript

      updateMultiPart({
        stoppageType: 'other',
        stoppageReason: undefined
      })
      updateMultiPart({
        stoppageType: type,
        stoppageCode: undefined
      })
    } else {
      updateMultiPart({
        stoppageType: 'kiln',
        stoppageCode: undefined
      })
      updateMultiPart({
        stoppageType: type,
        stoppageReason: undefined
      })
    }
  }

  return (
    <>
      <Box display="flex" gap={2} mb={2}>
        <TextField
          data-test-id="event-form-title"
          error={validationError.has('title')}
          fullWidth={true}
          helperText={getErrorText('title')}
          label={t(getTranslationKey('shiftEvent.label.title', translationPrefix))}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            updateMultiPart({title: event.target.value})
          }
          required
          value={item.title}
          variant="filled"
          InputProps={INPUT_FORM_FIELD_SX}
        />

        <EventHighlight
          title={t(getTranslationKey('eventsTable.action.highlight', translationPrefix))}
          value={item.isHighlighted}
          onChange={(value) => updateMultiPart({isHighlighted: value})}
        />
      </Box>

      <Box mb={1} mt={2}>
        <ToggleButtonGroup
          color={'primary'}
          sx={stoppageToggleButtonGroupSx}
          fullWidth
          exclusive
          value={item.eventType}
          onChange={(event: React.MouseEvent<HTMLElement>, value: StoppageKind) => {
            if (value) updateMultiPart({eventType: value})
          }}
        >
          {shifthandoverStoppagesList.map((codeType) => (
            <ToggleButton
              key={codeType}
              value={codeType}
              data-test-id={`event-form-stoppage-code-type-${codeType}`}
            >
              {t(getTranslationKey(`shiftEvent.stoppage.${codeType}`, translationPrefix))}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>

        <Grid container my={0.5} spacing={2} alignItems="flex-start">
          <Grid item xs={12}>
            <EventStoppageTypeDropDown
              data-test-id="event-form-stoppageType"
              value={item.stoppageType ?? 'other'}
              fullWidth={true}
              required
              onChange={onStoppageTypeChange}
              translationPrefix={translationPrefix}
            />
          </Grid>

          {withSalesImpact && (
            <SalesImpact
              item={item}
              update={updateMultiPart}
              costErrorText={getErrorText('cost')}
              currencyErrorText={getErrorText('costCurrency')}
              translationPrefix={translationPrefix}
            />
          )}

          {item.stoppageType &&
            isStoppageTypeWithCode(item.stoppageType) &&
            'stoppageCode' in item && (
              <Grid item xs={12}>
                <EventStoppageCodesDropDown
                  errorText={getErrorText('stoppageCode')}
                  onChange={(code) => {
                    if (code?.stoppageCategory) {
                      updateMultiPart({
                        stoppageType: item.stoppageType,
                        stoppageCode: code?.code,
                        category: code.stoppageCategory
                      })
                    } else {
                      updateMultiPart({
                        stoppageType: item.stoppageType,
                        stoppageCode: code?.code
                      })
                    }
                  }}
                  kilnStoppageCodes={stoppageCodes.kilnStoppageCodes}
                  rawMillStoppageCodes={stoppageCodes.rawMillStoppageCodes}
                  finishMillStoppageCodes={stoppageCodes.finishMillStoppageCodes}
                  value={item.stoppageCode}
                  stoppageType={item.stoppageType}
                  stoppageKind={item.eventType}
                  required={isStoppageTypeWithCode(item.stoppageType)}
                  translationPrefix={translationPrefix}
                />
              </Grid>
            )}
          {item.stoppageType &&
            isStoppageTypeWithReason(item.stoppageType) &&
            'stoppageReason' in item && (
              <Grid item xs={12}>
                <TextField
                  data-test-id="event-form-stoppageReason"
                  error={validationError.has('stoppageReason')}
                  fullWidth={true}
                  helperText={getErrorText('stoppageReason')}
                  label={t(getTranslationKey('shiftEvent.label.stoppageReason', translationPrefix))}
                  multiline
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    updateMultiPart({
                      stoppageType: item.stoppageType,
                      stoppageReason: event.target.value
                    })
                  }
                  required
                  minRows={3}
                  maxRows={10}
                  value={item.stoppageReason}
                />
              </Grid>
            )}

          {!hideStoppageType && item.eventType && (
            <EventTypeTag
              eventType={item.eventType}
              sx={{height: 72, display: 'flex', alignItems: 'center'}}
            />
          )}

          {runningTimesData && (
            <Grid item xs={12}>
              <ToggleButtonGroup
                color={'primary'}
                sx={stoppageToggleButtonGroupSx}
                fullWidth
                exclusive
                value={dateSelectionType}
                onChange={(event: React.MouseEvent<HTMLElement>, value: DateSelectionType) => {
                  if (!isNil(value)) setDateSelectionType(value)
                }}
              >
                {[DateSelectionType.List, DateSelectionType.Manual].map((selectionType) => (
                  <ToggleButton
                    key={selectionType}
                    value={selectionType}
                    data-test-id={`event-form-stoppage-date-type-${selectionType}`}
                    sx={{gap: 0.75}}
                  >
                    {DateSelectionType.List === selectionType ? (
                      <FormatListBulletedIcon />
                    ) : (
                      <CalendarTodayOutlinedIcon />
                    )}
                    {t(
                      getTranslationKey(`shiftEvent.stoppage.${selectionType}`, translationPrefix)
                    )}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
          )}
          {dateSelectionType === DateSelectionType.Manual ? (
            <>
              <Grid item xs={12}>
                {/* TODO add visual for an validation error  */}
                <SimpleDateTimePicker
                  label={t(
                    getTranslationKey('shiftEvent.stoppage.stoppageStart', translationPrefix)
                  )}
                  date={item.stoppageStart ? item.stoppageStart : null}
                  errorText={getErrorText('stoppageStart') || validationError.get('stoppageStart')}
                  required={true}
                  timezone={timezone}
                  handleDateChange={(date) => {
                    updateMultiPart({
                      stoppageStart: date || undefined,
                      pxTrendTag: undefined,
                      pxTrendStoppage: undefined
                    })
                  }}
                  disabled={durationEditOptions.disableStart}
                />
              </Grid>
              <Grid item xs={12}>
                {/* TODO add visual for an validation error  */}
                <SimpleDateTimePicker
                  timezone={timezone}
                  label={t(getTranslationKey('shiftEvent.stoppage.stoppageEnd', translationPrefix))}
                  errorText={getErrorText('stoppageEnd') || validationError.get('stoppageEnd')}
                  date={item.stoppageEnd ? item.stoppageEnd : null}
                  handleDateChange={(date) => {
                    updateMultiPart({
                      stoppageEnd: date || undefined,
                      pxTrendTag: undefined,
                      pxTrendStoppage: undefined
                    })
                  }}
                  disabled={durationEditOptions.disableEnd}
                />
              </Grid>
            </>
          ) : runningTimesData ? (
            <Grid item xs={12} zeroMinWidth>
              <StoppageTimeDropDown
                runningTimesData={{
                  ...runningTimesData,
                  data: runningTimesList
                }}
                value={selectedRunningTimeId}
                errorText={getErrorText('stoppageStart') || validationError.get('stoppageStart')}
                data-test-id="event-form-stoppage-time"
                onChange={(id: string) => {
                  setSelectedRunningTimeId(id)
                  const runningTimeItem = runningTimesList.find((rt) => rt.id === id)
                  if (runningTimeItem) {
                    updateMultiPart({
                      stoppageType: getEventStoppageType(runningTimeItem.equipment),
                      stoppageStart: runningTimeItem.runningTime.beginTechnical,
                      stoppageEnd: runningTimeItem.runningTime.endTechnical,
                      pxTrendTag: runningTimeItem.equipment.id,
                      pxTrendStoppage: runningTimeItem.runningTime
                    })
                  }
                }}
              />
            </Grid>
          ) : (
            <></>
          )}

          {withCategory && (
            <Grid item {...REGULAR_SIZE}>
              <CategoryDropDown
                data-test-id="event-form-category"
                errorText={getErrorText('category')}
                onChange={(value) => updateMultiPart({category: value})}
                value={item.category}
                translationPrefix={translationPrefix}
              />
            </Grid>
          )}
          {withPriority && (
            <Grid item {...REGULAR_SIZE}>
              <PriorityDropDown
                data-test-id="event-form-priority"
                errorText={getErrorText('priority')}
                onChange={(value) => updateMultiPart({priority: value})}
                value={item.priority as Priority}
                translationPrefix={translationPrefix}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <EquipmentSearchContainer
              data-test-id="event-form-equipmentNumber"
              errorText={getErrorText('equipmentNumber')}
              updateMultiPart={updateMultiPart}
              required
              value={item as Stoppage}
              plantId={plantId}
              translationPrefix={translationPrefix}
            />
          </Grid>
          <Grid item {...REGULAR_SIZE}>
            <ProcessStageDropDown
              plantId={plantId}
              data-test-id="event-form-processStage"
              errorText={getErrorText('processStage')}
              onChange={(value) => updateMultiPart({processStage: value})}
              value={item.processStage}
              translationPrefix={translationPrefix}
            />
          </Grid>
          <Grid item xs={12}>
            <EventDescriptionBox
              current={item.description || ''}
              errorCode={validationError.get('description')}
              onChange={(description) => updateMultiPart({description})}
              required
              translationPrefix={translationPrefix}
            />
          </Grid>
        </Grid>
      </Box>

      {children}
    </>
  )
}
